var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{ref:"editEventDatesModal",attrs:{"title":_vm.$t('editEvent'),"hide-footer":_vm.showConfirm,"sm":_vm.showConfirm},on:{"ok":_vm.SEND_TO_UPDATE_EVENT_ITEM},model:{value:(_vm.editEventDates),callback:function ($$v) {_vm.editEventDates=$$v},expression:"editEventDates"}},[(_vm.alertBox.status)?_c('div',{staticClass:"alert",class:`alert-${_vm.alertBox.variant}`},[_vm._v(" "+_vm._s(_vm.alertBox.message)+" ")]):_vm._e(),(_vm.showConfirm)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("eventRemoveDesc"))+" "),_c('br'),_c('strong',[_vm._v(" "+_vm._s(_vm.$t("doyouwanttoit")))]),_c('div',{staticClass:"mt-4 text-right"},[_c('button',{staticClass:"btn btn-pill m-1",on:{"click":function($event){_vm.showConfirm = false}}},[_vm._v(" "+_vm._s(_vm.$t("back"))+" ")]),_c('button',{staticClass:"btn btn-pill m-1 btn-danger",attrs:{"disabled":_vm.isLoadingRemoveEventItem},on:{"click":_vm.SEND_TO_REMOVE_EVENT_ITEM}},[(_vm.isLoadingRemoveEventItem)?_c('loading'):_c('span',[_vm._v(" "+_vm._s(_vm.$t("eventCancel")))])],1)])]):_c('div',[_c('div',{staticClass:"bg-light card"},[_vm._v(" "+_vm._s(_vm.$t("eventRemoveDesc2"))+" "),_c('div',{staticClass:"mt-2 text-right"},[_c('button',{staticClass:"btn btn-pill btn-danger",attrs:{"disabled":_vm.isLoadingRemoveEventItem},on:{"click":function($event){_vm.showConfirm = true}}},[(_vm.isLoadingRemoveEventItem)?_c('loading'):_c('span',[_vm._v(" "+_vm._s(_vm.$t("eventCancel")))])],1)])]),_c('hr'),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8 my-2"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("eventDate")))]),_c('date-picker',{staticClass:"w-100",attrs:{"lang":{
            formatLocale: {
              firstDayOfWeek: 1,
            },
          },"format":"DD.MM.YYYY","type":"date"},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}})],1),_c('div',{staticClass:"col-12 col-md-8 my-2"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("eventHour"))+" (GMT+3)")]),_c('date-picker',{staticClass:"w-100",attrs:{"minute-step":30,"format":"HH:mm","value-type":"format","type":"time","placeholder":"HH:mm"},model:{value:(_vm.form.timeString),callback:function ($$v) {_vm.$set(_vm.form, "timeString", $$v)},expression:"form.timeString"}})],1),_c('div',{staticClass:"col-12 col-md-8 my-2"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("eventDuration")))]),_c('date-picker',{staticClass:"w-100",attrs:{"format":"HH:mm","hour-options":_vm.hours,"value-type":"format","type":"time","placeholder":"HH:mm"},model:{value:(_vm.form.durationString),callback:function ($$v) {_vm.$set(_vm.form, "durationString", $$v)},expression:"form.durationString"}})],1),_c('div',{staticClass:"col-12 my-2"},[_c('h5',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t("hiddenInfoText")))]),_c('vue-editor',{attrs:{"id":"editor","placeholder":_vm.$t('hiddenInfoContent'),"editorToolbar":[
            [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            [{ size: ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', 'strike'],
            [
              { align: '' },
              { align: 'center' },
              { align: 'right' },
              { align: 'justify' },
            ],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
            [{ color: [] }, { background: [] }],
            ['link'],
            ['clean'],
          ]},model:{value:(_vm.form.hiddenInfo),callback:function ($$v) {_vm.$set(_vm.form, "hiddenInfo", $$v)},expression:"form.hiddenInfo"}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }