<template>
  <div class="mb-4">
    <div class="bg-light card p-4 mt-3">
      <h3>{{ $t("convertEventToCourseTitle") }}</h3>
      <p class="mt-2">{{ $t("convertEventToCourseDescription") }}</p>
      <div class="text-center mt-4">
        <button @click="showModal = true" class="btn btn-pill btn-danger">
          {{ $t("convertEventToCourse") }}
        </button>
      </div>
    </div>
    <Modal sm v-model="showModal" @ok="CONVERT_ITEM">
      {{ $t("convertEventToCourseModalDescription") }}
    </Modal>
  </div>
</template>

<script>
import Modal from "../Shared/Modal/index.vue";
import AlertBox from "../../mixins/AlertBox";
export default {
  data() {
    return {
      showModal: false,
    };
  },
  mixins: [AlertBox],
  props: {
    event: {
      default: () => {},
    },
  },
  components: { Modal },
  methods: {
    async CONVERT_ITEM(loading) {
      loading(true);
      const response = await this.$api.post(
        `Courses/FromItem?eventItemId=${this.event.id}`
      );
      if (response.message === "OK") {
        this.SET_ALERT_BOX("success", this.$t("convertedEventToCourse"));
        setTimeout(() => {
          this.showModal = false;
          this.$router.push(`/product/courses-${response.data}`);
        }, 2500);
      } else {
        this.SET_ALERT_BOX("danger", this.$t("convertedEventToCourseFail"));
      }
      loading(false);
    },
  },
};
</script>

<style></style>
