<template>
  <div class="container">
    <!-- <div class="my-5 text-right">
      <button
        v-if="videoSrcData !== ''"
        @click="videoSrcData = ''"
        class="btn-pill btn btn-dark"
      >
        {{ $t("changeEventItemVideo") }}
      </button>
      <button
        v-if="videoSrcData === '' && videoSrc !== ''"
        @click="videoSrcData = videoSrc"
        class="btn-pill btn btn-danger"
      >
        {{ $t("cancelActionEventItemVideo") }}
      </button>
    </div> -->
    <div>
      <h5 class="mt-3">{{ $t("information") }}</h5>
      <p class="bg-light card card my-3">
        {{ $t("eventItemVideoUploadingMessage2") }}
      </p>
      <p class="bg-light card my-3">
        {{ $t("eventItemVideoUploadingMessage3") }}
      </p>
      <p class="alert alert-danger my-3" v-if="videoLoadingIndex > 0">
        {{ $t("eventItemVideoUploadingMessage") }}
      </p>
      <div v-if="!preparing.first">
        <h4 class="text-primary">
          <strong> {{ $t("eventVideoTitle", { no: 1 }) }} </strong>
        </h4>
        <p>
          {{ $t("eventVideoDescription", { date: formatter(event.date) }) }}
        </p>
      </div>
      <div v-if="preparing.first" class="text-center card">
        <h4 class="text-primary">
          <strong> {{ $t("eventVideoPreparing") }} </strong>
        </h4>
        <h5>
          <strong> {{ $t("eventVideoPreparing2") }} </strong>
        </h5>
        <div class="text-center">
          <img src="/images/video-upload.jpg" width="400" alt="" />
        </div>
      </div>
      <GetVideo
        v-else-if="videoSrcData !== ''"
        :videosLink="videoLink"
        :options="videoOptions"
        @video:error="ERROR_VIDEO('first')"
        class="mb-4 mt-4"
      ></GetVideo>
      <UploadArea
        :percent="
          Math.ceil((uploadedByte.startByte * 100) / uploadedByte.totalByte) ||
          1
        "
        :uploading="
          $store.state.progressEvent.uploading && videoLoadingIndex === 1
        "
        @response:file="(e) => SEND_VIDEO(e, 1)"
        v-else-if="videoLoadingIndex === 0 || videoLoadingIndex === 1"
      ></UploadArea>
      <!-- <Loading v-else /> -->
      <div v-if="!preparing.second" class="mt-5">
        <h4 class="text-primary">
          <strong> {{ $t("eventVideoTitle", { no: 2 }) }} </strong>
        </h4>
        <p>
          {{ $t("eventVideoDescription", { date: formatter(event.date) }) }}
        </p>
      </div>
      <div v-if="preparing.second" class="text-center card">
        <h4 class="text-primary">
          <strong> {{ $t("eventVideoPreparing") }} </strong>
        </h4>
        <h5>
          <strong> {{ $t("eventVideoPreparing2") }} </strong>
        </h5>
        <div class="text-center">
          <img src="/images/video-upload.jpg" width="400" alt="" />
        </div>
      </div>
      <GetVideo
        v-else-if="videoSecondSrcData !== ''"
        :videosLink="videoLinkSecond"
        @video:error="ERROR_VIDEO('second')"
        :options="videoSecondOptions"
      ></GetVideo>
      <UploadArea
        :percent="
          Math.ceil((uploadedByte.startByte * 100) / uploadedByte.totalByte) ||
          1
        "
        :uploading="
          $store.state.progressEvent.uploading && videoLoadingIndex === 2
        "
        @response:file="(e) => SEND_VIDEO(e, 2)"
        v-if="
          event.secVideoPath === null &&
          (videoLoadingIndex === 0 || videoLoadingIndex === 2)
        "
      ></UploadArea>
    </div>
    <div class="bg-light card p-4 mt-4">
      <div class="option">
        <h3 class="mt-2">{{ $t("productAccessTime") }} *</h3>
        <p class="mb-4">{{ $t("lifeTimeAccessDescWithDate") }}</p>
        <p v-if="fullAccess" class="bg-light card">
          {{ $t("lifeAccessDateDesc") }}
        </p>
        <label for="">{{ $t("lastAccessDate") }} *</label>
        <date-picker
          :lang="{
            formatLocale: {
              firstDayOfWeek: 1,
            },
          }"
          class="w-100"
          format="DD.MM.YYYY"
          :disabled-date="notBeforeToday"
          @change="fullAccess = false"
          v-model="expiredAtData"
          type="date"
        ></date-picker>
        <div class="my-2">
          <input
            type="checkbox"
            name=""
            :checked="fullAccess"
            @change="SET_LIFE_TIME_CHECKBOX"
            id="lifetime"
          />
          <label for="lifetime"> {{ $t("selectLifeTimeAccess") }}</label>
        </div>
        <div class="mt-4 text-right">
          <button @click="UPDATE_EXPIRED_AT()" class="btn btn-pill donate">
            {{ $t("update") }}
          </button>
        </div>
      </div>
    </div>
    <ConvertEventToCourse
      v-if="event.videoPath"
      :event="event"
    ></ConvertEventToCourse>
  </div>
</template>

<script>
import GetVideo from "../VideoDetail/Video.vue";
import DatePicker from "vue2-datepicker";
import UploadArea from "./UploadArea.vue";
import AlertBox from "../../mixins/AlertBox";
import ConvertEventToCourse from "./ConvertEventToCourse.vue";
import { spliceFile } from "../../helpers/spliceFile";
import { CreateGuid } from "../../helpers/guid";
import { formatter } from "gurhan/helpers/Date";
export default {
  mixins: [AlertBox],
  components: {
    GetVideo,
    DatePicker,
    UploadArea,
    ConvertEventToCourse,
  },
  props: {
    videoSrc: {
      default: "",
    },
    expiredAt: {
      default: "",
    },
    event: {
      default: () => {},
    },
    product: {
      default: () => {},
    },
    videoSecondSrc: {
      default: "",
    },
  },
  data() {
    return {
      videoLink: {
        token: "",
        link: "",
      },
      videoLinkSecond: {
        token: "",
        link: "",
      },
      expiredAtData: "",
      fullAccess: false,
      videoSrcData: "",
      videoSecondSrcData: "",
      videoOptions: {
        live: true,
        autoplay: true,
        controls: true,
        playbackRates: [0.5, 1, 1.5, 2],
        sources: [
          {
            src: "",
            type: "application/x-mpegURL",
          },
        ],
      },
      videoSecondOptions: {
        live: true,
        autoplay: true,
        controls: true,
        playbackRates: [0.5, 1, 1.5, 2],
        sources: [
          {
            src: "",
            type: "application/x-mpegURL",
          },
        ],
      },
      type: {},
      videoLoadingIndex: 0,
      uploadedByte: {
        lastByte: 0,
        startByte: 0,
        totalByte: 1,
      },
      preparing: {
        first: false,
        second: false,
      },
    };
  },
  methods: {
    formatter,
    ERROR_VIDEO(index) {
      this.preparing[index] = true;
    },
    SET_LIFE_TIME_CHECKBOX() {
      if (!this.fullAccess) {
        this.fullAccess = true;
        this.expiredAtData = "";
      } else {
        this.expiredAtData = new Date();
        this.fullAccess = false;
      }
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    async SEND_VIDEO(files, index) {
      this.videoLoadingIndex = index;

      const fileArr = spliceFile(files[0]);
      if (fileArr.length > 0)
        this.SEND_VIDEO_RECURSIVE(fileArr, fileArr[0], 0, index, files[0].name);
      else {
        this.SET_ALERT_BOX("danger", this.$t("insertIsNotSucceed"));
        this.videoLoadingIndex = 0;
      }
      // const fd = new FormData();
      // fd.append("Video", files[0]);
      // fd.append(
      //   "ExpiredAt",
      //   new Date(this.expiredAtData || "9999-12-31").toLocaleDateString(
      //     "en-CA"
      //   ) || "9999-12-31"
      // );
      // const response = await this.$api.post(
      //   `EventItems/AddVideo?eventItemId=${this.event.id}`,
      //   fd
      // );
      // if (response.message === "OK") {
      //   this.videoLoadingIndex = 0;
      //   this.SET_ALERT_BOX("success", this.$t("insertIsSucceed"));
      //   setTimeout(() => {
      //     this.$emit("refresh"); g
      //   }, 1000);
      // } else {
      //   this.SET_ALERT_BOX("danger", this.$t("insertIsNotSucceed"));
      //   this.videoLoadingIndex = 0;
      // }
    },
    async SEND_VIDEO_RECURSIVE(fileArr, chunk, order, index, name) {
      this.videoLoadingIndex = index;
      const fd = new FormData();
      const IsLastPart = fileArr.length === order + 1;
      fd.append("Video", chunk.blob);
      fd.append("Order", order + 1);
      fd.append("FileName", CreateGuid() + "-" + name.substr(name.length - 8));

      fd.append("IsLastPart", IsLastPart);
      fd.append(
        "ExpiredAt",
        new Date(this.expiredAtData || "9999-12-31").toLocaleDateString(
          "en-CA"
        ) || "9999-12-31"
      );
      const response = await this.$api.post(
        `EventItems/AddVideo?eventItemId=${this.event.id}`,
        fd
      );
      if (response.message === "OK" && IsLastPart) {
        // işlem başarılı ve son part ise yüklemeyi tamamla.
        this.videoLoadingIndex = 0;
        this.SET_ALERT_BOX("success", this.$t("insertIsSucceed"));
        setTimeout(() => {
          this.$emit("refresh");
        }, 1000);
      } else if (response.message === "OK") {
        // işlem başarılı ise diğer chunk ve order'ı ayarla ve tekrar func cağır.
        const newOrder = order + 1;
        const newChunk = fileArr[newOrder];
        this.SEND_VIDEO_RECURSIVE(fileArr, newChunk, newOrder, index, name);
        this.uploadedByte = chunk;
      } else {
        // yükleme işlemi hatalı ise tekrar dene.
        const newChunk = fileArr[order];
        this.SEND_VIDEO_RECURSIVE(fileArr, newChunk, order, index);
        this.uploadedByte = chunk;
      }
    },
    SET_FIELDS() {
      setTimeout(async () => {
        const splitVideoLength = this.event?.videoPath?.split("/") || [];
        if (splitVideoLength.length > 1) {
          const response = await this.$api.get(
            "EventItems/GetCDNStream/" + this.event.id + "/1"
          );
          if (response.message === "OK") {
            this.videoLink = response.data;
            this.videoOptions.sources[0].src = response.data.link;
          }
        } else {
          this.videoOptions.sources[0].src = `${
            this.$api.BASE_URL
          }/EventItems/GetStream/${this.event.id}/${"720"}/part.m3u8`;
        }
        // this.videoOptions.sources[0].src =
        //   this.$api.BASE_URL +
        //   `EventItems/GetStream/${this.event.id}/720/part.m3u8`;
        this.fullAccess = this.event.expiredAt.includes("9999-12-31")
          ? true
          : false;
        this.expiredAtData = this.event.expiredAt.includes("9999-12-31")
          ? ""
          : new Date(this.event.expiredAt);
        this.videoSrcData = this.videoSrc;
      }, 250);
    },
    SET_SECOND_FIELDS() {
      setTimeout(async () => {
        const splitVideoLength = this.event?.videoPath?.split("/") || [];
        if (splitVideoLength.length > 1) {
          const response = await this.$api.get(
            "EventItems/GetCDNStream/" + this.event.id + "/2"
          );
          if (response.message === "OK") {
            this.videoLinkSecond = response.data;
            this.videoSecondOptions.sources[0].src = response.data.link;
          }
        } else {
          this.videoSecondOptions.sources[0].src = `${
            this.$api.BASE_URL
          }/EventItems/GetStream/${this.event.id}/${"720"}/part.m3u8`;
        }
        this.fullAccess = this.event.expiredAt.includes("9999-12-31")
          ? true
          : false;
        this.expiredAtData = this.event.expiredAt.includes("9999-12-31")
          ? ""
          : new Date(this.event.expiredAt);
        this.videoSecondSrcData = this.videoSecondSrc;
      }, 250);
    },
    async UPDATE_EXPIRED_AT() {
      this.SET_LOADING_MODAL(true);
      const response = await this.$store.dispatch("SEND_TO_UPDATE_EVENT_ITEM", {
        ...this.event,
        expiredAt: new Date(
          this.expiredAtData || "9999-12-31"
        ).toLocaleDateString("en-CA"),
        productId: this.product.id,
      });
      if (response) {
        this.SET_ALERT_BOX("success", this.$t("updatedIsSucceed"));
        setTimeout(() => {
          this.$emit("refresh");
        }, 1000);
      } else this.SET_ALERT_BOX("danger", this.$t("updatedIsNotSucceed"));
    },
    GET_PRODUCT() {
      try {
        this.id = this.$route.params.eventId;
        const type = this.Types.find((t) => t.statePath === "events");
        if (type) {
          this.type = type;
          this.$store.dispatch("GET_PRODUCT", {
            path: `${type.storePath}/${this.id}`,
            id: Number(this.id),
            type,
          });
        } else this.$router.push("/error");
      } catch (error) {
        this.$router.push("/error");
      }
    },
  },
  mounted() {
    this.SET_FIELDS();
    this.SET_SECOND_FIELDS();
  },
};
</script>

<style></style>
