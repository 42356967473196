<template>
  <div class="position-relative">
    <div class="uploading-file" v-if="uploading">
      <div class="pie" :class="`p${Math.round(percent)}`"></div>
      {{ $t("yourFileIsUploading") }}
    </div>
    <div class="position-relative" v-else>
      <div
        @click="$refs.file.click()"
        class="upload-area"
        :class="{ 'on-dragging': isDragging }"
        @dragover.prevent="isDragging = true"
        @dragleave.prevent="isDragging = false"
        @drop="GET_DROP_ITEM"
      >
        <input
          type="file"
          accept="video/*"
          class="d-none"
          @change="SET_FILES"
          ref="file"
        />
        <img
          src="/images/upload.svg"
          width="40"
          style="filter: brightness(0)"
          alt="Upload"
        />
        <p v-if="isDragging">{{ $t("dropFile") }}</p>
        <p v-else>{{ $t("dragFileForUpload") }}</p>
        <p>
          <small>{{ $t("acceptPdfFile") }}</small>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
function CONTROL_OF_FILES(files) {
  let valid = true;
  for (let i = 0; i < files.length; i++) {
    const element = files[i];
    if (!element.type.includes("video")) valid = false;
  }
  return valid;
}

export default {
  data() {
    return {
      isDragging: false,
      fileList: [],
    };
  },
  props: {
    percent: {
      default: 0,
    },
    uploading: {
      default: false,
    },
    files: {
      default: () => [],
    },
  },
  methods: {
    GET_DROP_ITEM(e) {
      e.preventDefault();
      this.isDragging = false;
      const files = e.dataTransfer.files;
      if (files.length > 1) return;
      if (!CONTROL_OF_FILES(files)) return;
      this.$refs.file.files = files;
      this.UPLOAD_FILES(files);
    },
    SET_FILES(e) {
      this.UPLOAD_FILES(e.target.files);
    },
    UPLOAD_FILES(files) {
      if (files.length === 0) return;
      this.fileList = files;
      this.$emit("response:file", files);
    },
  },
};
</script>

<style></style>
