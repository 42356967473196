var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',[_c('h5',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t("information")))]),_c('p',{staticClass:"bg-light card card my-3"},[_vm._v(" "+_vm._s(_vm.$t("eventItemVideoUploadingMessage2"))+" ")]),_c('p',{staticClass:"bg-light card my-3"},[_vm._v(" "+_vm._s(_vm.$t("eventItemVideoUploadingMessage3"))+" ")]),(_vm.videoLoadingIndex > 0)?_c('p',{staticClass:"alert alert-danger my-3"},[_vm._v(" "+_vm._s(_vm.$t("eventItemVideoUploadingMessage"))+" ")]):_vm._e(),(!_vm.preparing.first)?_c('div',[_c('h4',{staticClass:"text-primary"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("eventVideoTitle", { no: 1 }))+" ")])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("eventVideoDescription", { date: _vm.formatter(_vm.event.date) }))+" ")])]):_vm._e(),(_vm.preparing.first)?_c('div',{staticClass:"text-center card"},[_c('h4',{staticClass:"text-primary"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("eventVideoPreparing"))+" ")])]),_c('h5',[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("eventVideoPreparing2"))+" ")])]),_vm._m(0)]):(_vm.videoSrcData !== '')?_c('GetVideo',{staticClass:"mb-4 mt-4",attrs:{"videosLink":_vm.videoLink,"options":_vm.videoOptions},on:{"video:error":function($event){return _vm.ERROR_VIDEO('first')}}}):(_vm.videoLoadingIndex === 0 || _vm.videoLoadingIndex === 1)?_c('UploadArea',{attrs:{"percent":Math.ceil((_vm.uploadedByte.startByte * 100) / _vm.uploadedByte.totalByte) ||
        1,"uploading":_vm.$store.state.progressEvent.uploading && _vm.videoLoadingIndex === 1},on:{"response:file":(e) => _vm.SEND_VIDEO(e, 1)}}):_vm._e(),(!_vm.preparing.second)?_c('div',{staticClass:"mt-5"},[_c('h4',{staticClass:"text-primary"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("eventVideoTitle", { no: 2 }))+" ")])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("eventVideoDescription", { date: _vm.formatter(_vm.event.date) }))+" ")])]):_vm._e(),(_vm.preparing.second)?_c('div',{staticClass:"text-center card"},[_c('h4',{staticClass:"text-primary"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("eventVideoPreparing"))+" ")])]),_c('h5',[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("eventVideoPreparing2"))+" ")])]),_vm._m(1)]):(_vm.videoSecondSrcData !== '')?_c('GetVideo',{attrs:{"videosLink":_vm.videoLinkSecond,"options":_vm.videoSecondOptions},on:{"video:error":function($event){return _vm.ERROR_VIDEO('second')}}}):_vm._e(),(
        _vm.event.secVideoPath === null &&
        (_vm.videoLoadingIndex === 0 || _vm.videoLoadingIndex === 2)
      )?_c('UploadArea',{attrs:{"percent":Math.ceil((_vm.uploadedByte.startByte * 100) / _vm.uploadedByte.totalByte) ||
        1,"uploading":_vm.$store.state.progressEvent.uploading && _vm.videoLoadingIndex === 2},on:{"response:file":(e) => _vm.SEND_VIDEO(e, 2)}}):_vm._e()],1),_c('div',{staticClass:"bg-light card p-4 mt-4"},[_c('div',{staticClass:"option"},[_c('h3',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("productAccessTime"))+" *")]),_c('p',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.$t("lifeTimeAccessDescWithDate")))]),(_vm.fullAccess)?_c('p',{staticClass:"bg-light card"},[_vm._v(" "+_vm._s(_vm.$t("lifeAccessDateDesc"))+" ")]):_vm._e(),_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("lastAccessDate"))+" *")]),_c('date-picker',{staticClass:"w-100",attrs:{"lang":{
          formatLocale: {
            firstDayOfWeek: 1,
          },
        },"format":"DD.MM.YYYY","disabled-date":_vm.notBeforeToday,"type":"date"},on:{"change":function($event){_vm.fullAccess = false}},model:{value:(_vm.expiredAtData),callback:function ($$v) {_vm.expiredAtData=$$v},expression:"expiredAtData"}}),_c('div',{staticClass:"my-2"},[_c('input',{attrs:{"type":"checkbox","name":"","id":"lifetime"},domProps:{"checked":_vm.fullAccess},on:{"change":_vm.SET_LIFE_TIME_CHECKBOX}}),_c('label',{attrs:{"for":"lifetime"}},[_vm._v(" "+_vm._s(_vm.$t("selectLifeTimeAccess")))])]),_c('div',{staticClass:"mt-4 text-right"},[_c('button',{staticClass:"btn btn-pill donate",on:{"click":function($event){return _vm.UPDATE_EXPIRED_AT()}}},[_vm._v(" "+_vm._s(_vm.$t("update"))+" ")])])],1)]),(_vm.event.videoPath)?_c('ConvertEventToCourse',{attrs:{"event":_vm.event}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":"/images/video-upload.jpg","width":"400","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('img',{attrs:{"src":"/images/video-upload.jpg","width":"400","alt":""}})])
}]

export { render, staticRenderFns }