<template>
  <div class="mt-4">
    <h2>
      {{ $t("events") }}:
      {{
        formatter(new Date(event.date), {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
      }}
    </h2>
    <span v-if="badge.status" class="badge" :class="`badge-${badge.variant}`">
      {{ badge.text }}</span
    >
    <h5 class="text-muted mt-2 mb-4">
      {{ $t("usersOfEvent") }}
    </h5>
  </div>
</template>

<script>
import { formatter, convertM2H } from "gurhan/helpers/Date";
export default {
  props: {
    event: {
      default: () => {},
    },
    badge: {
      default: () => {},
    },
  },
  methods: {
    formatter,
    convertM2H,
  },
};
</script>

<style></style>
