export function spliceFile(file) {
  const slicedFileArr = [];
  let slicedBytes = 0;
  let byteRange = 5 * 1024 * 1024; // 5mb => 5 000 000b minimum kabul edilen
  const fileBytes = file.size;
  while (fileBytes > slicedBytes) {
    let lastByte = byteRange + slicedBytes;
    if (lastByte > fileBytes) lastByte = fileBytes;
    if (fileBytes - slicedBytes < byteRange * 2) lastByte = fileBytes;
    const byte = file.slice(slicedBytes, lastByte);
    slicedFileArr.push({
      blob: byte,
      totalByte: fileBytes,
      startByte: slicedBytes,
      lastByte,
    });
    slicedBytes = lastByte;
  }
  return slicedFileArr;
}
