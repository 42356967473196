<template>
  <div>
    <div class="alert" :class="`alert-info`" v-if="alertBox.length > 0">
      <ul>
        <li v-for="(alert, i) in alertBox" :key="i">
          {{
            $t("alertMessageStudent", {
              fullname: alert.fullname,
              message: alert.message,
            })
          }}
          <hr />
        </li>
      </ul>
    </div>
    <h5>
      <strong> {{ $t("addStudentToCourse") }} </strong>
    </h5>
    <div class="text-muted">
      <small>
        {{ $t("addStudentToCourseDescription") }}
      </small>
    </div>
    <div v-if="selectedUsers.length > 0 && !hideAction">
      <strong class="color-default"
        ><img src="/images/success.svg" width="20" alt="" />
        {{ $t("addedStudentCount", { count: selectedUsers.length }) }}
      </strong>
      <div>
        <h5 class="mt-4">{{ $t("currencyItemWhoAddedToStudent") }}</h5>
        <div class="text-muted">
          <small> {{ $t("currencyItemWhoAddedToStudentDescription") }} </small>
        </div>
        <div class="mt-4">
          <label for="pay"> {{ $t("currency") }} </label>
          <select v-model="currency" name="pay" class="form-control" id="">
            <option selected hidden :value="null">
              {{ $t("chooseCurrency") }}
            </option>
            <option :value="item.type" v-for="(item, i) in prices" :key="i">
              {{ item.label }}
            </option>
          </select>
          <div class="mt-4 bg-light card">
            <h5>{{ $t("infoForCurrency") }} :</h5>
            <div class="mt-3">
              <ul>
                <li>- {{ $t("infoForCurrency2") }}</li>
                <li>- {{ $t("infoForCurrency3") }}</li>
                <li>- {{ $t("infoForCurrency4") }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div v-if="product[`price${currency}`] > 0">
          <div v-if="currency !== null" class="mt-4">
            <Apply v-model="promotion" :currency="currency">
              <h5 class="mb-2">{{ $t("addPromotionCodeToStudent") }}</h5>
              <p class="text-muted">
                {{ $t("addPromotionCodeToStudentDescription") }}
              </p>
              <div class="mt-2 alert alert-warning">
                <h6>{{ $t("warn") }} :</h6>
                <p>* {{ $t("promotionCodeWarnMessage") }}</p>
              </div>
            </Apply>
          </div>
          <div class="mt-4">
            <h5 class="mb-2">{{ $t("choosePaymentTitle") }}</h5>
            <p class="mb-4 text-muted">
              <span> {{ $t("choosePaymentDescription") }} </span>
            </p>
            <select v-model="status" name="pay" class="form-control" id="">
              <option selected hidden :value="null">
                {{ $t("choosePaymentStatus") }}
              </option>
              <option :value="1">{{ $t("waitPayment") }}</option>
              <option :value="2">{{ $t("completePayment") }}</option>
            </select>
            <div v-if="status === 2" class="alert alert-warning mt-2">
              {{ $t("choosedPaymentCompletedForStudent") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 text-center">
      <button
        @click="showUserAddModal = true"
        class="btn btn-xs btn-pill subscribe"
      >
        <span v-if="selectedUsers.length === 0">+ {{ $t("addUser") }}</span>
        <span v-else>+ {{ $t("addMoreStudent") }}</span>
      </button>
    </div>
    <Modal
      :okText="$t('continue')"
      v-model="showUserAddModal"
      :title="$t('addStudent')"
      @ok="SEND_LIST"
    >
      <div>
        <small> {{ $t("addStudentToEventDescription") }} </small>
      </div>
      <div class="mt-3" v-if="showUserAddModal">
        <div class="mb-3" v-if="lastEvent.id">
          <button
            class="btn btn-xs"
            @click="activeComponent = 'AllUserList'"
            :class="{ subscribe: activeComponent === 'AllUserList' }"
          >
            {{ $t("allStudents") }}
          </button>
          <button
            class="btn btn-xs"
            @click="activeComponent = 'UserListByProduct'"
            :class="{ subscribe: activeComponent === 'UserListByProduct' }"
          >
            {{ $t("studentsOfEvent", { date: formatter(lastEvent.date) }) }}
          </button>
        </div>
        <component
          :is="activeComponent"
          :productId="lastEvent.id || -1"
          :table="table"
          :init-selected-user-list="selectedUsers"
          @update:list="GET_SELECTED_USER_LIST"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "../../components/Shared/Modal/index.vue";
import AllUserList from "./AllUserList.vue";
import UserListByProduct from "./UserListByProduct.vue";
import Apply from "../PromotionCodes/apply.vue";
import { formatter } from "gurhan/helpers/Date";
export default {
  components: {
    Modal,
    AllUserList,
    UserListByProduct,
    Apply,
  },
  props: {
    lastEvent: {
      default: () => ({}),
    },
    table: {
      default: "",
    },
    product: {
      default: () => ({}),
    },
    initSelectedUsers: {
      default: () => [],
    },
    hideAction: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  data() {
    return {
      activeComponent: "AllUserList",
      showUserAddModal: false,

      selectedUsers: [],
      currency: "TRY",
      alertBox: [],
      promotion: { id: -1, status: "" },
      prices: [
        { type: "TRY", label: "TRY (₺)", isPaypal: 0 },
        { type: "USD", label: "USD ($)", isPaypal: 1 },
        { type: "EUR", label: "EUR (€)", isPaypal: 1 },
      ],
      status: 1,
    };
  },
  methods: {
    formatter,
    GET_SELECTED_USER_LIST(users) {
      console.log(users, "user list updated");
      this.selectedUsers = users;
    },
    SEND_LIST() {
      this.$emit("response", this.selectedUsers);
      this.showUserAddModal = false;
    },
    async ADD_USERS_TO_PRODUCT(id) {
      const responseAll = await Promise.all(
        this.selectedUsers.map((user) => this.SEND_USER_TO_PRODUCT(user, id))
      );
      this.alertBox = responseAll;
      return responseAll;
    },
    async SEND_USER_TO_PRODUCT(user, id) {
      if (this.product[`price${this.currency}`] === 0) {
        this.status = 2;
      }
      const alertBox = await this.$store.dispatch(
        this.product.type.addUserToProductByManual,
        {
          productId: id,
          userId: user.id,
          price: this.product[`price${this.currency}`],
          discount: this.product.discount,
          currency: this.currency,
          promotionCodeId: this.promotion.id === -1 ? null : this.promotion.id,
          paymentStatus: this.product[`price${this.currency}`] > 0 ? 1 : 2,
        }
      );
      const response =
        this.status === 1 || alertBox.variant === "danger"
          ? true
          : await this.SEND_UPDATE_USER_PRODUCT(user, id);
      this.alertBox.push({
        ...alertBox,
        ...user,
        updateResponse: response,
      });
      return { ...alertBox, ...user, updateResponse: response };
    },
    async SEND_UPDATE_USER_PRODUCT(user, id) {
      this.alertBox.status = false;
      const response = await this.$api.put(
        `Products/UpdateStatus?table=${this.product.type.name}`,
        {
          userId: user.id,
          productId: id,
          status: this.status,
          price: user.price,
          discount: user.discount,
        }
      );
      if (response.message === "OK") {
        return true;
        // this.$emit("input", false);
        // this.$emit("refresh");
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.selectedUsers = this.initSelectedUsers;
  },
};
</script>

<style></style>
