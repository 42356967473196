<template>
  <div id="selectedUser">
    <div
      @click="SCROLL_TO()"
      class="bg-white py-3 cursor-pointer"
      style="position: sticky; top: -20px"
      v-if="selectedUsers.length > 0"
    >
      <h6
        class="color-default d-flex justify-content-between align-items-center"
      >
        <div>
          <img src="/images/success.svg" width="20" alt="" />

          {{ $t("selectedStudent") }} ({{ selectedUsers.length }})
        </div>
        <div>
          <button
            @click="CLEAR_SELECTED_USER()"
            class="btn btn-xs btn-pill bg-danger text-white"
          >
            {{ $t("removeAll") }}
          </button>
        </div>
      </h6>
    </div>
    <ul class="mb-4" v-if="selectedUsers.length > 0">
      <li
        class="p-2 border d-flex justify-content-between align-items-center"
        v-for="(user, i) in selectedUsers"
        :key="i"
        :class="{
          'bg-light card-default': selectedUsers.find((u) => u.id === user.id),
        }"
      >
        <small><i class="icon-user"></i> {{ user.fullname }}</small>
        <button
          v-if="!selectedUsers.find((u) => u.id === user.id)"
          class="btn-xs btn-pill btn donate"
          @click="SELECT_USER(user)"
        >
          + {{ $t("add") }}
        </button>
        <button
          v-else
          @click="SELECT_USER(user)"
          class="btn-xs btn-pill btn bg-danger text-white"
        >
          - {{ $t("remove") }}
        </button>
      </li>
    </ul>
    <div v-if="allCount > selectedUsers.length || isLoading">
      <div v-if="selectedUsers.length > 0">
        <h6>{{ $t("addMoreStudent") }}</h6>
        <div>
          <small class="text-muted">
            {{ $t("addMoreStudentDescription") }}
          </small>
        </div>
      </div>
      <div v-else>{{ $t("addMoreStudentCount", { count: count }) }}</div>
      <div class="my-3">
        <button @click="GET_ALL_USER_LIST()" class="btn btn-xs">
          + {{ $t("addAllStudent", { count }) }}
        </button>
      </div>
      <div class="mt-1">
        <input
          type="text"
          v-model="search"
          class="w-100 form-control"
          :placeholder="$t('searchStudentName')"
        />
      </div>
      <hr />
      <div class="text-center" v-if="isLoading">
        <loading />
        <div>
          <small> {{ $t("loading") }} </small>
        </div>
      </div>
      <div v-else-if="users.length === 0" class="text-center">
        <small>{{ $t("noFoundData") }} </small>
      </div>
      <div v-else>
        <ul>
          <li class="mb-2">
            <h6 class="d-flex align-items-center justify-content-between">
              <span
                >{{ $t("results") }} ({{ users.length + (page - 1) * 10 }} /
                {{ count }})</span
              >
              <button @click="ADD_LIST()" class="btn btn-xs subscribe btn-pill">
                + {{ $t("addToList") }} ({{ users.length }})
              </button>
            </h6>
          </li>
          <li
            class="p-2 border d-flex justify-content-between align-items-center"
            v-for="(user, i) in users"
            :key="i"
            :class="{
              'bg-light card-default': selectedUsers.find(
                (u) => u.id === user.id
              ),
            }"
          >
            <small><i class="icon-user"></i> {{ user.fullname }}</small>
            <button
              v-if="!selectedUsers.find((u) => u.id === user.id)"
              class="btn-xs btn-pill btn donate"
              @click="SELECT_USER(user)"
            >
              + {{ $t("add") }}
            </button>
            <button
              v-else
              @click="SELECT_USER(user)"
              class="btn-xs btn-pill btn bg-danger text-white"
            >
              - {{ $t("remove") }}
            </button>
          </li>
        </ul>
        <div class="mt-4 d-flex justify-content-between align-items-center">
          <button
            :disabled="page === 1"
            @click="CHANGE_PAGE(page - 1)"
            class="btn btn-pill"
          >
            {{ $t("prev") }}
          </button>
          <span>{{ page }}. {{ $t("page") }}</span>
          <button
            :disabled="page === Math.ceil(count / 10)"
            @click="CHANGE_PAGE(page + 1)"
            class="btn btn-pill subscribe"
          >
            {{ $t("next") }}
          </button>
        </div>
      </div>
    </div>
    <div v-else class="text-center mt-4">
      <img src="/images/success.svg" width="60" alt="" />
      <h5 class="my-4">{{ $t("awesome") }}</h5>
      <h6>
        {{ $t("insertedAllStudent") }}
      </h6>
    </div>
  </div>
</template>

<script>
import Loading from "../Shared/Loading.vue";
export default {
  components: { Loading },
  data() {
    return {
      selectedUsers: [],
      users: [],
      count: 0,
      allCount: 0,
      allUsers: [],
      page: 1,
      isLoading: true,
      search: "",
      timeout: -1,
    };
  },
  watch: {
    search() {
      if (this.timeout !== -1) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.GET_USERS();
      }, 1000);
    },
  },
  props: {
    initSelectedUserList: {
      default: () => [],
    },
  },
  methods: {
    CLEAR_SELECTED_USER() {
      this.selectedUsers = [];
      this.$emit("update:list", []);
    },
    SCROLL_TO() {
      document
        .getElementById("selectedUser")
        .scrollIntoView({ behavior: "smooth", block: "start" });
    },
    CHANGE_PAGE(page) {
      this.page = page;
      this.GET_USERS();
    },
    ADD_LIST() {
      this.users.forEach((user) => {
        this.SELECT_USER(user, true);
      });
    },
    SELECT_USER(user, force = false) {
      const index = this.selectedUsers.findIndex((u) => u.id === user.id);
      if (index > -1 && !force) this.selectedUsers.splice(index, 1);
      else if (index > -1) return;
      else this.selectedUsers.push(user);
      this.$emit("update:list", this.selectedUsers);
    },
    async GET_ALL_USER_LIST() {
      this.isLoading = true;
      const response = await this.$api.get(
        `Users?searchKey=${this.search}&limit=${this.allCount}`
      );
      if (response.message === "OK") {
        response.data.items.forEach((item) => {
          this.SELECT_USER(item, true);
        });
      }
      this.isLoading = false;
    },
    async GET_USERS() {
      this.isLoading = true;
      const response = await this.$api.get(
        `Users?limit=10&searchKey=${this.search}&page=${this.page}`
      );
      if (response.message === "OK") {
        this.users = response.data.items;
        this.count = response.data.count;
        if (this.search === "") this.allCount = response.data.count;
      } else {
        this.users = [];
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.GET_USERS();
    this.selectedUsers = this.initSelectedUserList;
  },
};
</script>

<style></style>
