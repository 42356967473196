<template>
  <div>
    <slot></slot>
    <label for="code">{{ $t("promotionCode") }}</label>
    <input
      type="text"
      name="code"
      id="code"
      placeholder="Promosyon kodunu girin.."
      v-model="code"
      class="form-control"
    />
    <div class="mt-2 text-right">
      <button
        :disabled="loading"
        @click="GET_PROMOTION_CODE_VALUES"
        class="btn btn-pill donate"
      >
        <span v-if="loading"><Loading></Loading></span>
        <span v-else>{{ $t("apply") }} </span>
      </button>
    </div>
    <div
      v-if="promotion.status !== ''"
      class="mt-2 alert p-2"
      :class="{
        'alert-danger': promotion.status === 'invalidCode',
        'alert-success': promotion.status === 'ok',
      }"
    >
      <small>
        {{
          promotion.status === "ok"
            ? `${$t("appliedCode", { discount: promotion.discount })}(${
                promotion.code
              })`
            : $t("invalidCode")
        }}
      </small>
    </div>
  </div>
</template>

<script>
import Loading from "../Shared/Loading.vue";
export default {
  data() {
    return {
      code: "",
      promotion: { id: -1, status: "" },
      loading: false,
    };
  },
  props: {
    currency: {
      default: "TRY",
    },
    value: {
      default: () => ({ id: -1, status: "" }),
    },
  },
  watch: {
    promotion: {
      deep: true,
      handler(data) {
        this.$emit("input", data);
      },
    },
  },
  methods: {
    async GET_PROMOTION_CODE_VALUES() {
      this.loading = true;
      this.promotion = { id: -1, status: "" };
      const response = await this.$api.get(
        `PromotionCodes/Apply?code=${this.code}&currency=${
          this.currency === "TRY" ? 1 : this.currency === "USD" ? 2 : 3
        }`
      );
      if (response.message === "OK") {
        if (response.data !== null)
          this.promotion = { ...response.data, status: "ok" };
        else
          this.promotion = {
            id: -1,
            status: "invalidCode",
          };
      } else
        this.promotion = {
          id: -1,
          status: "invalidCode",
        };
      this.loading = false;
    },
  },
  components: { Loading },
};
</script>

<style></style>
